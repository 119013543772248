import React, { useEffect, useState } from "react";
import FormAddLink from "./FormAddLink";
import FormUpdateLinks from "./FormUpdateLinks";
import Api from "../Services/Api";

export default function Links() {
  // type 1: interno, 2: externo, 3: interno app
  const [formAddVisible, setFormAddVisible] = useState(false);
  const [formUpdateVisible, setFormUpdateVisible] = useState(false);

  const [dataFormUpdate, setDataFormUpdate] = useState({});
 
   const [data, setData] = useState([])
   const [dataEvents, setDataEvents] = useState([])

  const matchType = (type) => {
    switch (type) {
      case 1:
        return "Interno";
      case 2:
        return "Externo";
      case 3:
        return "Interno App";
      case 4:
        return "Evento";
      default:
        return "No definido";
    }
  };

  const matchSection = (section) => {
    switch (section) {
      case 1:
        return "Evento";
      case 2:
        return "Menu";
      case 3:
        return "Home Seccion 1";
      case 4:
        return "Home Seccion 2";
      case 5:
        return "Home Seccion 3 (Evento)";
      case 6:
        return "Home Seccion 4";
      case 7:
        return "Home Seccion 5";
      default:
        return "No definido";
    }
  }

  const matchEvent = (event) => {
    const data = dataEvents.find((item) => item.id === event);
    return data ? data.name : "No definido";
  }

  const handleUpdate = (data) => {
    setDataFormUpdate(data);
    setFormUpdateVisible(true);
  }

  useEffect(() => {
    getLinkExternal();
  }, []);

  const getLinkExternal = async () => {
    const response = await Api.getLinkExternal();
    setData(response.list_link_app);
    setDataEvents(response.list_events);
  }
 
  const deleteLink = async (id) => {
    const response = await Api.deleteLinkExternal(id);
    if (response) {
      window.location.reload();
    }
    else {
      alert('Error al eliminar')
    }
  }

  const getTextReducer = (text) => {
    if (text.length > 30) {
      return text.substring(0, 30) + "...";
    }
    return text;
  }

  return (
    <div>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Enlaces</div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              setFormAddVisible(true);
            }}
          >
            Agregar
          </button>
        </div>
      </section>
      <section>
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col" style={{width:"5%"}}>#</th>
              <th scope="col" style={{width:"15%"}}>Nombre</th>
              <th scope="col" style={{width:"20%"}}>URL</th>
              <th scope="col">Tipo</th>
              <th scope="col">Sección</th>
              <th scope="col">Estado</th>
              <th scope="col">Evento</th>
              <th scope="col" style={{textAlign:"center"}}>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((link, index) => {
              return (
                <tr valign="middle" key={`tr-link-${index}`}>
                  <th scope="row">{link.id}</th>
                  <td>{link.name}</td>
                  <td>{getTextReducer(link.redirect)}</td>
                  <td>{matchType(link.type)}</td>
                  <td>{matchSection(link.section)}</td>
                  <td>{link.visible ? "Activo" : "Inactivo"}</td>
                  <td>{matchEvent(link.event_id)}</td>
                  <td className="text-center" style={{display:"flex"}}>
                    <button
                      className="btn btn-primary mr-3"
                      type="button"
                      onClick={()=>{handleUpdate(link)}}
                    >
                      Editar
                    </button>
                    <button className="btn btn-danger mr-3" type="button" onClick={()=>{deleteLink(link.id)}}>
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <FormAddLink
        dataEvents={dataEvents}
        formAddVisible={formAddVisible}
        setFormAddVisible={setFormAddVisible}
      />
    <FormUpdateLinks
        dataEvents={dataEvents}
        data={dataFormUpdate}
        formAddVisible={formUpdateVisible}
        setFormAddVisible={setFormUpdateVisible}
    />
    </div>
  );
}
