import React, {Component} from 'react';

import HOMBRESILUETA from '../Layout/Assets/Avatarsthetopjuez/HOMBRESILUETA.png';
import HOMBRE1 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE1.png';
import HOMBRE2 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE2.png';
import HOMBRE3 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE3.png';
import HOMBRE4 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE4.png';
import HOMBRE5 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE5.png';
import HOMBRE6 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE6.png';
import HOMBRE7 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE7.png';
import HOMBRE8 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE8.png';
import HOMBRE9 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE9.png';
import HOMBRE10 from '../Layout/Assets/Avatarsthetopjuez/HOMBRE10.png';

import MUJERSILUETA from '../Layout/Assets/Avatarsthetopjuez/MUJERSILUETA.png';
import MUJER1 from '../Layout/Assets/Avatarsthetopjuez/MUJER1.png';
import MUJER2 from '../Layout/Assets/Avatarsthetopjuez/MUJER2.png';
import MUJER3 from '../Layout/Assets/Avatarsthetopjuez/MUJER3.png';
import MUJER4 from '../Layout/Assets/Avatarsthetopjuez/MUJER4.png';
import MUJER5 from '../Layout/Assets/Avatarsthetopjuez/MUJER5.png';
import MUJER6 from '../Layout/Assets/Avatarsthetopjuez/MUJER6.png';
import MUJER7 from '../Layout/Assets/Avatarsthetopjuez/MUJER7.png';
import MUJER8 from '../Layout/Assets/Avatarsthetopjuez/MUJER8.png';
import MUJER9 from '../Layout/Assets/Avatarsthetopjuez/MUJER9.png';
import MUJER10 from '../Layout/Assets/Avatarsthetopjuez/MUJER10.png';

export const AvatarsList = {
	HOMBRESILUETA,
	HOMBRE1,
	HOMBRE2,
	HOMBRE3,
	HOMBRE4,
	HOMBRE5,
	HOMBRE6,
	HOMBRE7,
	HOMBRE8,
	HOMBRE9,
	HOMBRE10,
	MUJERSILUETA,
	MUJER1,
	MUJER2,
	MUJER3,
	MUJER4,
	MUJER5,
	MUJER6,
	MUJER7,
	MUJER8,
	MUJER9,
	MUJER10,
}

const Avatars = (props) => {
	const onClick = (nameAvatar) => {
		const {handleAvatar} = props.data
		handleAvatar(nameAvatar)
		alert('Avatar actualizado')
	}
	return(
		<div className="row">
		<div className="col-12 mb-3">
		<p className="text-left">Elije tu avatar</p>
		<ul className="list-group list-group-horizontal">
			<li class="list-group-item p-0">
				<img src={MUJERSILUETA} className="img-fluid" onClick={()=>onClick('MUJERSILUETA')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE1} className="img-fluid" onClick={()=>onClick('HOMBRE1')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE2} className="img-fluid" onClick={()=>onClick('HOMBRE2')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE3} className="img-fluid" onClick={()=>onClick('HOMBRE3')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE4} className="img-fluid" onClick={()=>onClick('HOMBRE4')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE5} className="img-fluid" onClick={()=>onClick('HOMBRE5')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE6} className="img-fluid" onClick={()=>onClick('HOMBRE6')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE7} className="img-fluid" onClick={()=>onClick('HOMBRE7')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE8} className="img-fluid" onClick={()=>onClick('HOMBRE8')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE9} className="img-fluid" onClick={()=>onClick('HOMBRE9')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={HOMBRE10} className="img-fluid" onClick={()=>onClick('HOMBRE10')}/>
			</li>
		</ul>
		<ul className="list-group list-group-horizontal">
			<li class="list-group-item p-0">
				<img src={HOMBRESILUETA} className="img-fluid" onClick={()=>onClick('HOMBRESILUETA')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER1} className="img-fluid" onClick={()=>onClick('MUJER1')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER2} className="img-fluid" onClick={()=>onClick('MUJER2')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER3} className="img-fluid" onClick={()=>onClick('MUJER3')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER4} className="img-fluid" onClick={()=>onClick('MUJER4')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER5} className="img-fluid" onClick={()=>onClick('MUJER5')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER6} className="img-fluid" onClick={()=>onClick('MUJER6')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER7} className="img-fluid" onClick={()=>onClick('MUJER7')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER8} className="img-fluid" onClick={()=>onClick('MUJER8')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER9} className="img-fluid" onClick={()=>onClick('MUJER9')}/>
			</li>
			<li class="list-group-item p-0">
				<img src={MUJER10} className="img-fluid" onClick={()=>onClick('MUJER10')}/>
			</li>
		</ul>
		</div>
		</div>
	);
}
export default Avatars;