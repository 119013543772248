import React, { Component } from "react";
/* global gapi */
//import placeholder_restaurant from '../Layout/Assets/placeholder-restaurant.jpg';

import InputFileImg from "../Layout/Components/InputFileImg";
import FormProcessing from "../Services/FormProcessing";
import { Spinner } from "reactstrap";
import SweetAlert from "sweetalert2-react";
import { Link } from "react-router-dom";
import Api from "../Services/Api";
import Paginate from "../Layout/Components/Paginate";
import UserImg from "../Layout/Assets/user.png";
import Session from "../Services/Session";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
      product: false,
      votings: false,
      loading: true,
      restaurant: false,
      loadingForm: false,
      showAlert: false,
      showAlertType: "success",
      pagination: null,
      resetForm: false,
      lock_voting: 0,
      toggleLoginModal: false,

      defaultComment: localStorage.getItem("last_vote_description")
        ? localStorage.getItem("last_vote_description")
        : "",
      defaultNumBoleta: localStorage.getItem("last_vote_numero_de_boleta")
        ? localStorage.getItem("last_vote_numero_de_boleta")
        : "",
      nombre:
        localStorage.getItem("name") == "" ? "" : localStorage.getItem("name"),
      apellido:
        localStorage.getItem("surname") == ""
          ? ""
          : localStorage.getItem("surname"),
      fecha_Nacimiento:
        localStorage.getItem("fecha_Nacimiento") == ""
          ? ""
          : localStorage.getItem("fecha_Nacimiento"),
      rut: localStorage.getItem("rut") == "" ? "" : localStorage.getItem("rut"),

      nombreExist: localStorage.getItem("name") == "" ? false : true,
      fecha_NacimientoExist:
        localStorage.getItem("fecha_Nacimiento") == "" ? false : true,
      rutExist: localStorage.getItem("rut") == "" ? false : true,
      apellidoExist: localStorage.getItem("surname") == "" ? false : true,

      defaultRating: localStorage.getItem("last_vote_calificacion")
        ? localStorage.getItem("last_vote_calificacion")
        : 0,
      termsChecked: false,
      params_events: {
        nombre: 0,
        rut: 0,
        fecha_Nacimiento: 0,
      },
    };
    this.productDetail = this.productDetail.bind(this);
    this.form = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loginSocial = this.loginSocial.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  async loginSocial(type) {
    const res = await Api.getLinkSocial({
      product_id: this.state.slug,
      type: type,
      redirect_uri: window.location.href,
    });
    localStorage.setItem("last_product_id", this.state.slug);
    console.log("res: ", res)
    window.location.replace(res.url);
  }
  UNSAFE_componentWillMount() {

    window.scrollTo(0, 0);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    console.log("token: ", urlParams);

    if (localStorage.getItem("last_product_id") && token) {
        const type_profile = urlParams.get("type_profile");
        const status = urlParams.get("status");
        const username = urlParams.get("username");
        const full_name = urlParams.get("full_name");
        const avatar = urlParams.get("avatar");

        const params = {
            token,
            type_profile,
            status,
            username,
            full_name,
            avatar,
        };
        Session.open(params);
        const slug = localStorage.getItem("last_product_id");
        localStorage.removeItem("last_product_id");
        console.log("SLUG: ", slug);
        window.location.replace(slug);
    } else {
        console.log("Obteniendo detalles: ", this.state.slug);
        this.productDetail({}, this.state.slug);
    }

    Api.getParamsEvents({
      id: localStorage.getItem("country") != null ? localStorage.getItem("country") : 1,
    })
    .then((response) => {
        this.setState({
          params_events: {
            nombre: response.nombre,
            rut: response.rut,
            fecha_Nacimiento: response.fecha_Nacimiento
          },
        });
        console.log("Datos recuperados:", this.state);
    })
    .catch((error) => {
        // Manejar errores en la solicitud
        console.error("Error en la solicitud:", error);
    });
  }

  async productDetail(page = {}, slug = null) {
    slug = slug == null ? this.state.slug : slug;
    let response = await Api.product(slug, page);
    let votings = false;
    let pagination = null;
    if (typeof response.votings === "object") {
      votings = !!response.votings.data.length ? response.votings.data : false;
      pagination = !!response.votings.data.length
        ? {
            last_page: response.votings.last_page,
            current_page: response.votings.current_page,
            per_page: response.votings.per_page,
          }
        : null;
    }
    this.setState({
        product: response.product,
        votings,
        pagination,
        loading: false,
        showAlert: false,
        lock_voting: response.lock_voting,
        restaurant: !!response.product.restaurant.length
            ? response.product.restaurant[0]
            : null,
    });
  }

  async handleSubmit(e) {
    let userNameUpdate = localStorage.getItem("username");
    const { sendForm } = this;
    const { termsChecked, nombre, apellido, fecha_Nacimiento, rut } =
      this.state;
    if (
      termsChecked &&
      (nombre != "" || this.state.params_events.nombre != 2) &&
      (apellido != "" || this.state.params_events.nombre != 2) &&
      (fecha_Nacimiento != "" || this.state.params_events.fecha_Nacimiento != 2) &&
      (rut != "" || this.state.params_events.rut != 2)
    ) {
        let response = await Api.updateUserVoting(userNameUpdate, {
            rut: rut,
            birth_date: fecha_Nacimiento,
            name: nombre,
            surname: apellido,
        });

        localStorage.setItem("name", nombre != "" ? nombre : "");
        localStorage.setItem("surname", apellido != "" ? apellido : "");
        localStorage.setItem(
            "fecha_Nacimiento",
            fecha_Nacimiento != "" ? fecha_Nacimiento : ""
        );
        localStorage.setItem("rut", rut != "" ? rut : "");
        window.grecaptcha.ready(function () {
        window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE, { action: "vote" })
            .then(function (token) {
                sendForm(token, termsChecked);
            });
        });
    } else if (
      (nombre == "" && this.state.params_events.nombre == 2) ||
      (apellido == "" && this.state.params_events.nombre == 2) || (
        fecha_Nacimiento == "" && this.state.params_events.fecha_Nacimiento == 2
      ) ||
      (rut == "" && this.state.params_events.rut == 2)
    ) {
      let state = {
        success: "Rellene los campos obligatorios",
        showAlertType: "error",
        showAlert: true,
      };
      this.setState(state);
    } else if (!termsChecked) {
      let state = {
        success: "Acepta los terminos y Condiciones!",
        showAlertType: "error",
        showAlert: true,
      };
      this.setState(state);
    }
  }

  handleTermsChange = () => {
    this.setState((prevState) => ({
      termsChecked: !prevState.termsChecked,
    }));
  };

  async sendForm(token, termsChecked) {
    this.setState({ toggleLoginModal: true });
    this.setState({ loadingForm: true });
    let data = await FormProcessing.toObject(this.form.current);

    if (localStorage.getItem("last_vote_image")) {
      data.photo = {
        file: localStorage.getItem("last_vote_image"),
        name: localStorage.getItem("last_vote_image_title"),
      };
    }
    data.token = token;
    data.termsChecked = termsChecked ? 1 : 0;
    let response = null;
    let state = {};
    let validate = [];
    response = await Api.voteProduct(data);
    if (response.enable_validations === true) {
      validate = response;
    }
    FormProcessing.validate(this.form.current, validate);
    if (response.success) {
      state = {
        success: response.success,
        showAlertType: "success",
        showAlert: true,
        resetForm: !this.state.resetForm,
      };

      this.form.current.reset();

      localStorage.removeItem("last_vote_description", "");
      localStorage.removeItem("last_vote_numero_de_boleta", "");
      localStorage.removeItem("last_vote_calificacion", "");

      localStorage.removeItem("last_vote_image", "");
      localStorage.removeItem("last_vote_image_title", "");
      localStorage.removeItem("last_vote_image_name", "");
      localStorage.removeItem("last_vote_image_value", "");

      this.setState({
        defaultComment: "",
        defaultNumBoleta: "",
        defaultRating: 0,
      });
    }

    if (response.error) {
      console.log(typeof response.error);
      state = {
        success:
          typeof response.error === "object"
            ? "Completa el formulario para votar!"
            : response.error,
        showAlertType: "error",
        showAlert: true,
        resetForm: !this.state.resetForm,
      };
    }
    state.loadingForm = false;
    this.setState(state);
  }

  onChange(e) {
    localStorage.setItem("last_vote_" + e.target.name, e.target.value);

    let newState = {};

    if (e.target.name == "description") {
      newState.defaultComment = e.target.value;
    }

    if (e.target.name == "calificacion") {
      newState.defaultRating = e.target.value;
    }

    if (e.target.name == "numero_de_boleta") {
      newState.defaultNumBoleta = e.target.value;
    }

    if (e.target.name == "nombre") {
      newState.nombre = e.target.value;
    }

    if (e.target.name == "apellido") {
      newState.apellido = e.target.value;
    }

    if (e.target.name == "fecha_Nacimiento") {
      newState.fecha_Nacimiento = e.target.value;
    }

    if (e.target.name == "rut") {
      newState.rut = this.formatRut(e.target.value);
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }
  formatRut(rut) {
    if (rut) {
      return rut
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
    return "";
  }

  render() {
    let {
      product,
      restaurant,
      loadingForm,
      votings,
      showAlert,
      success,
      showAlertType,
      pagination,
      resetForm,
      lock_voting,
      slug,
      defaultComment,
      defaultNumBoleta,
      defaultRating,
      nombre,
      rut,
      fecha_Nacimiento,
      fecha_NacimientoExist,
      rutExist,
      apellido,
    } = this.state;
    console.log(this.state);
    const { loginSocial, onChange } = this;
    const { termsChecked } = this.state;
    console.log("is auth: ", Session.isAuth());

    return (
      <div className="row mt-5">
        <SweetAlert
          show={showAlert}
          title="Wow!"
          type={showAlertType}
          text={success}
          onConfirm={(e) => this.productDetail(this.props.match.params.slug)}
        />
        <div className="col-md-12">
          <h1 className="text-danger">{product.name}</h1>
          <hr />
          <p>
            <strong>Restaurante: </strong>
            {restaurant.name}
          </p>
        </div>
        <div className="col-md-4">
          <img alt="" className="img-fluid border" src={product.logo_url} />
          <div className="mt-4">
            <u>Descripción</u>
            <br />
            <p
              className="card-text"
              dangerouslySetInnerHTML={{ __html: product.description }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          </div>
        </div>
        <form
          className={"col-md-8" + (lock_voting === 0 ? "" : " d-none")}
          onSubmit={this.handleSubmit}
          ref={this.form}
        >
          {typeof product === "object" ? (
            <input
              name="product_id"
              defaultValue={product.id}
              className="d-none"
            />
          ) : (
            ""
          )}
          <p className="text-danger text-left">
            Completa el formulario para votar!
          </p>
          <br />
          <label>
            <strong>Califica el producto *</strong>
          </label>
          <br />
          <div className="rate px-0">
            <input
              type="radio"
              id="star5"
              name="calificacion"
              value="5"
              onChange={onChange}
              checked={defaultRating == 5}
            />
            <label htmlFor="star5" title="text">
              5 stars
            </label>
            <input
              type="radio"
              id="star4"
              name="calificacion"
              value="4"
              onChange={onChange}
              checked={defaultRating == 4}
            />
            <label htmlFor="star4" title="text">
              4 stars
            </label>
            <input
              type="radio"
              id="star3"
              name="calificacion"
              value="3"
              onChange={onChange}
              checked={defaultRating == 3}
            />
            <label htmlFor="star3" title="text">
              3 stars
            </label>
            <input
              type="radio"
              id="star2"
              name="calificacion"
              value="2"
              onChange={onChange}
              checked={defaultRating == 2}
            />
            <label htmlFor="star2" title="text">
              2 stars
            </label>
            <input
              type="radio"
              id="star1"
              name="calificacion"
              value="1"
              onChange={onChange}
              checked={defaultRating == 1}
            />
            <label htmlFor="star1" title="text">
              1 star
            </label>
          </div>
          <br />
          <br />
          <br />
          <div className="form-group mt-2">
            <label>Número de boleta *</label>
            <input
              name="numero_de_boleta"
              className="form-control"
              onChange={onChange}
              defaultValue={defaultNumBoleta}
            />
          </div>

          {this.state.params_events.nombre != 0 && (
            <div className="form-group mt-2">
              <label>
                Nombre {this.state.params_events.nombre == 2 ? "*" : ""}
              </label>
              <input
                name="nombre"
                className="form-control"
                value={nombre}
                onChange={onChange}
              />
            </div>
          )}

          {this.state.params_events.nombre != 0 && (
            <div className="form-group mt-2">
              <label>
                Apellido {this.state.params_events.nombre == 2 ? "*" : ""}
              </label>
              <input
                name="apellido"
                className="form-control"
                value={apellido}
                onChange={onChange}
              />
            </div>
          )}

          {this.state.params_events.rut != 0 && (
            <div className="form-group mt-2">
              <label>Rut {this.state.params_events.rut == 2 ? "*" : ""}</label>
              <input
                name="rut"
                className="form-control"
                value={rut}
                onChange={onChange}
              />
            </div>
          )}

          {this.state.params_events.fecha_Nacimiento != 0 && (
            <div className="form-group mt-2">
              <label>
                Fecha de Nacimiento{" "}
                {this.state.params_events.fecha_Nacimiento == 2 ? "*" : ""}
              </label>
              <input
                name="fecha_Nacimiento"
                className="form-control"
                value={fecha_Nacimiento}
                onChange={onChange}
                type="date"
              />
            </div>
          )}
          <div className="form-group mt-4 pt-2">
            <label>Comentario (opcional)</label>
            <textarea
              className="form-control"
              rows="5"
              name="description"
              onChange={onChange}
              defaultValue={defaultComment}
            ></textarea>
          </div>
          <div className="form-group">
            <InputFileImg
              name="photo"
              iconClass="img text-center d-block"
              reset={resetForm}
            />
          </div>
          <hr />
          <div className="form-group text-center bg-light pt-5">
            <div className="form-group">
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={termsChecked}
                onChange={this.handleTermsChange}
              />
              <label htmlFor="terms" className="ml-1">
                Al votar aceptas los{" "}
                <a
                  target="_blank"
                  href="https://thetop.cl/post/terminos-y-condiciones/"
                >
                  Términos y Condiciones.
                </a>
              </label>
            </div>
            {!Session.isAuth() ? (
              <>
                <a
                  className="btn border m-1 bg-white"
                  href={"/sign/in" + "?return_url=" + window.location.href}
                  style={{ width: "350px" }}
                >
                  <img
                    style={{ margin: "0 5px" }}
                    width="20px"
                    alt="The top sign in"
                    src={process.env.PUBLIC_URL + "/favicon.png"}
                  />
                  Inicia sesión con nosotros
                </a>
              </>
            ) : (
              <>
                <button
                  className={`btn ${
                    Session.isAuth() ? "btn-success" : "btn-secondary"
                  } px-5 btn-block`}
                  disabled={loadingForm || lock_voting || !Session.isAuth()}
                  type="button"
                  onClick={this.handleSubmit}
                >
                  <span className="px-5">
                    {loadingForm ? (
                      <span className="h3">
                        <Spinner
                          size="sm"
                          color="white"
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            top: "-7px",
                            position: "relative",
                            right: "9px",
                          }}
                        />
                        Votando..
                      </span>
                    ) : (
                      <span className="h3">Votar</span>
                    )}
                  </span>
                </button>
              </>
            )}
          </div>
        </form>
        <div className={lock_voting === 0 ? "d-none" : "col-md-8 text-center"}>
          <h1 className="my-5 py-5">
            <i className="fa fa-check  fa-2x text-success" />
            <br />
            Gracias
            <br />
            Por participar
          </h1>
        </div>
        <div className="col-md-12">
          <hr />
          <h1 className="text-center">Votos</h1>
          <hr />
        </div>
        {typeof votings === "object" ? (
          votings.map(function (v, i) {
            var rating = [];
            for (var j = 1; j <= 5; j++) {
              if (v.rating >= j) {
                rating.push(
                  <i
                    className="fas fa-star fa-lg text-warning"
                    key={"s-" + j}
                  />
                );
              } else {
                rating.push(
                  <i
                    className="far fa-star fa-lg text-warning"
                    key={"s-" + j}
                  />
                );
              }
            }
            //console.log(v.description)
            return (
              <div className="col-12" key={"v-" + i}>
                <Link className="row" to={"/juez/" + v.user.username}>
                  <div className="col-md-12 mt-4 px-1 text-dark">
                    <div className="card mb-3">
                      <div className="row no-gutters">
                        <div className="col-md-2 text-center">
                          {v.media_id !== null ? (
                            <img
                              className="img-fluid border"
                              alt={v.user.username + "-my-voto-" + slug}
                              src={v.photo_url}
                            />
                          ) : (
                            <h5 className="pt-5">Sin foto</h5>
                          )}
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <div className="card-title">
                              <img
                                alt=""
                                className="img-fluid border"
                                width="50"
                                src={
                                  v.user.avatar_url === null
                                    ? UserImg
                                    : v.user.avatar_url
                                }
                              />
                              {rating}
                              <br />
                              <strong className="h3">{v.user.full_name !=" " ? v.user.full_name: v.user.email.split("@")[0] }</strong>
                            </div>
                            <p
                              className="card-text"
                              dangerouslySetInnerHTML={{
                                __html: v.description,
                              }}
                              style={{ whiteSpace: "pre-wrap" }}
                            />

                            <p className="card-text">
                              <small className="text-muted">
                                fecha de votacion: {v.created_at}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          }, slug)
        ) : (
          <div className="col-md-12">
            <h3 className="text-center text-info">
              <i className="fa fa-close fa-lg"></i>
              <br />
              Sin votaciones
            </h3>
          </div>
        )}
        <div className="col-12 d-flex justify-content-center">
          {pagination != null ? (
            <Paginate
              last_page={pagination.last_page}
              current_page={pagination.current_page}
              per_page={pagination.per_page}
              loadList={this.productDetail}
              classNamew="border border-bottom-0 bg-white px-5 pt-4"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
export default Product;
